<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>All In Box</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2021</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end/back-end, webdesign, création d'un
								module
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Au cours de mon année de Licence Professionnelle
						Conception-Intégration d'Applications et Services Web pour
						l'Entreprise (CIASIE), j'ai effectué mon stage dans l'entreprise All
						In Box qui est spécialisée dans le domaine de la programmation
						informatique et plus précisément dans la récupération de la donnée
						client et dans son analyse pour aider les autres entreprises à
						regrouper et mieux utiliser leurs données.<br /><br />
						Au sein de celle-ci, j'ai été affecté à un projet de création d'un
						module d'e-réputation. Cela consiste à créer un service
						supplémentaire à la solution initiale que propose All In Box afin de
						permettre à ses utilisateurs d’avoir un meilleur contrôle de l’image
						de leur entreprise sur Internet. Pour cela, le module doit aller
						récupérer tous les avis utilisateurs laissés sur les plateformes tel
						que Google, Tripadvisor, Facebook, etc. grâce à leurs APIs afin de
						les afficher et donner l’opportunité à l’utilisateur d’All In Box
						d’y répondre. Grâce à ces données récupérées, le module doit ensuite
						permettre de générer diverses statistiques utiles afin que
						l’utilisateur puisse avoir conscience du statut actuel de son
						e-réputation et de son évolution dans le temps pour pouvoir agir en
						conséquence. Ainsi, j’ai eu pour objectif de créer complètement ce
						module en passant de la conception au développement côté serveur, à
						la réalisation des maquettes des pages jusqu’au développement côté
						client.<br /><br />
						J’ai pour cela créé un système afin que l’utilisateur d’All In Box
						puisse lier des plateformes pour accéder aux avis laissés sur
						celles-ci et y répondre si besoin. J’en ai par la suite ressorti des
						statistiques concrètes afin que l’utilisateur puisse observer
						l’évolution de son e-réputation via des graphiques. Outre les API
						des diverses plateformes, j’ai par ailleurs eu l’occasion d’utiliser
						l’API Natural Language que propose Google afin d’analyser les avis
						pour en ressortir une note alternative en se basant sur le sentiment
						général du texte. Par rapport à l’environnement technique, je me
						suis adapté au code déjà présent dans l'application en travaillant
						via les langages de programmation PHP et MySQL pour le côté serveur
						et en respectant notamment le paradigme de programmation du Single
						source of truth. Concernant le côté client, je me suis aussi adapté
						en me servant du template de base de l'application, en utilisant les
						langages de programmation HTML | CSS | JS et en utilisant
						différentes librairies notamment pour la création de graphiques.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a href="https://apps.allinbox.fr/" class="boutonVoirSite"
							>LE SITE D'ALL IN BOX</a
						>
					</div>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | All In Box",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le module d'e-réputation de l'application \"All In Box\" réalisé par Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "ALL IN BOX - MODULE D'E-RÉPUTATION",
				urlImage: require("../assets/projets/fond_all_in_box.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
